import React from "react"

export default function Logo() {
	return (
		<p
			style={{
				display: "block",
				height: "auto",
			}}
		>
			🚀 wp-graphql-gutenberg
		</p>
	)
}

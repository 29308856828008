module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":72},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":72}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":[]}},{"resolve":"gatsby-remark-mermaid","options":{"mermaidOptions":{"themeCSS":"\n            .node rect,\n            .node circle,\n            .node polygon {\n              stroke-width: 2px;\n              stroke: #3f20ba;\n              fill: #F4F6F8;\n            }\n            .node.secondary rect,\n            .node.secondary circle,\n            .node.secondary polygon,\n            .node.tertiary rect,\n            .node.tertiary circle,\n            .node.tertiary polygon {\n              fill: white;\n            }\n            .node.secondary rect,\n            .node.secondary circle,\n            .node.secondary polygon {\n              stroke: #f25cc1;\n            }\n            .cluster rect,\n            .node.tertiary rect,\n            .node.tertiary circle,\n            .node.tertiary polygon {\n              stroke: #41d9d3;\n            }\n            .cluster rect {\n              fill: none;\n              stroke-width: 2px;\n            }\n            .edgeLabel {\n              background-color: white;\n            }\n          "}}},"gatsby-remark-code-titles",{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":true}},"gatsby-remark-rewrite-relative-links",{"resolve":"gatsby-remark-check-links","options":{}}],"remarkPlugins":[[null,{"wrapperComponent":"MultiCodeBlock"}]]},
    },{
      plugin: require('../node_modules/gatsby-theme-apollo-docs/gatsby-browser.js'),
      options: {"plugins":[],"defaultVersion":"0.2.0","siteName":"WpGraphQL Gutenberg Docs","menuTitle":"WpGraphQL Gutenberg Menu","subtitle":"WpGraphQL Gutenberg","root":"/opt/build/repo/docs","description":"WpGraphQL Gutenberg documentation","sidebarCategories":{"null":["index"],"Getting Started":["getting-started/installation","getting-started/overview","getting-started/how-to-query","getting-started/revisions-and-previews"],"Previews":["previews/overview"],"Server":["server/overview","server/installation","server/lambda-functions"],"Addons":["addons/acf-blocks","addons/extending","addons/woocommerce"],"Contributing":["contributing/contributing"],"API Reference":["api/api"]}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    }]
